import React, { useEffect, useState } from 'react'

import { CheckIcon } from '@heroicons/react/24/outline'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { Grid2 as Grid, TextField as MuiTextField } from '@mui/material'
import Button from '@mui/material/Button'
import { LearnerTask } from 'types/graphql'

import { FieldError, Form, Label } from '@redwoodjs/forms'

import DebounceTextInput from 'src/components/DebounceTextInput/DebounceTextInput'
import VideoComponent from 'src/components/Learner/Library/VideoComponent/VideoComponent'
import LessonTextLayoutOptions from 'src/components/LessonTextLayoutOptions/LessonTextLayoutOptions'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import RichTextEditor from 'src/components/Library/RichTextEditor/RichTextEditor'
import WarningLabel from 'src/components/Library/WarningLabel/WarningLabel'
import useAnalytics from 'src/lib/hooks/useAnalytics'

interface Data {
  text?: string
  learnerActivityId?: number
  name?: string
  video?: string
  textLayout?: string
}

interface Option {
  id: number
  name?: string
}

interface Props {
  learnerTask: LearnerTask
  error?: Error
  loading: boolean
  options: Option[]
  activityIndex: number

  onSave(input: any, id: number)

  setSaving(bool: boolean)

  setEditing(bool: boolean)
}

const VideoTaskForm: React.FC<Props> = ({
  learnerTask,
  onSave,
  setEditing,
  setSaving,
  options,
  activityIndex,
}) => {
  const shouldHideText = () =>
    !learnerTask?.text || learnerTask.text === '<p></p>'

  const [visibleText, setVisibleText] = useState<boolean>(shouldHideText())
  const [videoURL, setVideoURL] = useState<string>('')
  const [selectedActivityId, setSelectedActivityId] = useState(-1)
  const [showVideo, setShowVideo] = useState<boolean>(false)
  const [lessonTitle, setLessonTitle] = useState<string>('')
  const [chapterValue, setChapterValue] = useState<Option | null>(
    options[activityIndex],
  )
  const [showVideoTick, setShowVideoTick] = useState(false)

  const [textEditorValue, setTextEditorValue] = useState('')
  const [updateData, setUpdateData] = useState<boolean>(false)

  const [textLayout, setTextLayout] = useState('fullWidth')
  const { trackEvent } = useAnalytics()

  useEffect(() => {
    if (learnerTask?.video && videoURL !== learnerTask.video) {
      // visible url different to database url - set to database url
      setVideoURL(learnerTask.video)
      setShowVideo(true)
    }
    if (!learnerTask?.video) {
      // if its not a video that reset the videoURL
      setVideoURL('')
      setShowVideo(false)
      setShowVideoTick(false)
    } else {
      setShowVideoTick(true)
    }
  }, [learnerTask?.video])

  useEffect(() => {
    setTextEditorValue(learnerTask?.text ? learnerTask.text : '')
  }, [learnerTask?.text])

  useEffect(() => {
    // set state value for title if there is already a title in the database
    if (learnerTask?.name && lessonTitle !== learnerTask.name) {
      setLessonTitle(learnerTask.name)
    }
  }, [learnerTask?.name])

  useEffect(() => {
    const hideText = shouldHideText()
    setVisibleText(!hideText)
  }, [learnerTask?.text])

  const handleTitleChange = (value) => {
    setLessonTitle(value)
    setUpdateData(true)
  }

  const handleVideoChange = (value) => {
    setVideoURL(value)
    setUpdateData(true)
  }

  const handleDebounceRichTextEditorChange = (value?: string) => {
    setTextEditorValue(value)
    setUpdateData(true)
  }

  const handleTextLayoutToggle = (layout: string) => {
    setTextLayout(layout)
    setUpdateData(true)
    trackEvent('Learner', 'Toggle Text Layout', { textLayout: layout })
  }

  useEffect(() => {
    if (learnerTask?.textLayout && learnerTask?.textLayout !== textLayout) {
      setTextLayout(learnerTask.textLayout)
    }
  }, [learnerTask?.textLayout])

  const onChange = () => {
    const data: Data = {}
    if (!videoURL) {
      data.video = ''
      setShowVideo(false)
    } else {
      data.video = videoURL
      setShowVideo(true)
    }

    if (!lessonTitle) {
      data.name = ''
    } else {
      data.name = lessonTitle
    }

    if (selectedActivityId === -1) {
      data.learnerActivityId = learnerTask?.learnerActivityId
    } else {
      data.learnerActivityId = selectedActivityId
    }

    if (textEditorValue) {
      data.text = textEditorValue
    } else {
      data.text = ''
    }

    data.textLayout = textLayout || ''

    if (learnerTask) {
      if (
        learnerTask.text === data.text &&
        learnerTask.learnerActivityId === data.learnerActivityId &&
        learnerTask.name === data.name &&
        learnerTask.video === data.video &&
        learnerTask.textLayout === data.textLayout
      ) {
        setEditing(false)
        setUpdateData(false)
        return
      }
      setSaving(true)
      setEditing(false)
      onSave(data, learnerTask?.id)
    }
    setSelectedActivityId(-1)
    setUpdateData(false)
  }

  useEffect(() => {
    if (updateData) {
      setEditing(true)
      onChange()
    }
  }, [updateData])

  // prevent save on lesson change
  useEffect(() => {
    setUpdateData(false)
  }, [learnerTask?.id])

  return (
    <div className="px-4">
      <Form>
        <Grid container rowSpacing={1} columnSpacing={0.5}>
          <Grid size={{ xs: 2 }}>
            <Label name="learnerActivityTitle" className="rw-label pl-10">
              Title
            </Label>
          </Grid>
          <Grid size={{ xs: 10 }}>
            <DebounceTextInput
              setKey={learnerTask?.id}
              name="title"
              placeholder="Title of the lesson"
              className="mt-6 pl-10"
              defaultValue={learnerTask?.name}
              debounceTimeout={1500}
              onChange={(value) => {
                return value !== learnerTask?.name
                  ? handleTitleChange(value)
                  : null
              }}
              isRequired={true}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Label
              name="learnerActivityId"
              className="rw-label pl-10"
              errorClassName="rw-label rw-label-error"
            >
              Chapter
            </Label>
          </Grid>
          <Grid size={{ xs: 10 }}>
            <div className="mt-4 pl-10">
              <Autocomplete
                sx={{ '& .MuiAutocomplete-input': { height: 5 } }}
                key={learnerTask?.id}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disablePortal
                onChange={(_, newValue: Option) => {
                  setSelectedActivityId(newValue.id)
                  setChapterValue(newValue) // Update the chapterValue state here
                  setUpdateData(true)
                }}
                value={chapterValue} // Use chapterValue as the value
                id="combo-box-demo"
                options={options}
                getOptionLabel={(option: Option) => option.name}
                defaultValue={chapterValue} // Use chapterValue as the defaultValue
                renderInput={(params) => <MuiTextField {...params} />}
              />
            </div>
          </Grid>
          <Grid container size={{ xs: 2 }} direction="row" columnSpacing={1.5}>
            <Grid size={{ xs: 2 }}>
              {showVideoTick && (
                <CheckIcon className="mt-6 h-6 w-6 text-green-500" />
              )}
            </Grid>
            <Grid size={{ xs: 2 }}>
              <Label
                name="video"
                className="rw-label"
                style={{ marginLeft: 'calc(1rem - 2%)', paddingLeft: '20%' }}
                errorClassName="rw-label rw-label-error"
              >
                Video
              </Label>
              <Label
                name="video-optional"
                style={{ marginLeft: 'calc(1rem - 2%)', paddingLeft: '20%' }}
                className="rw-label mt-1 text-xs text-gray-400"
              >
                Optional
              </Label>
            </Grid>
          </Grid>
          <Grid size={{ xs: 10 }}>
            <DebounceTextInput
              setKey={learnerTask?.id}
              name="video"
              placeholder="https://..."
              className="mt-5 pl-10"
              defaultValue={videoURL}
              debounceTimeout={1500}
              onChange={(value) => {
                return value !== learnerTask?.video
                  ? handleVideoChange(value)
                  : null
              }}
              isRequired={false}
            />
            <FieldError name="video" className="rw-field-error" />
          </Grid>
          {showVideo && (
            <Grid
              container
              size={{ xs: 12 }}
              direction="row"
              columnSpacing={1.5}
              justifyContent="flex-end"
              alignItems="top"
            >
              <Grid size={{ xs: 5 }}>
                <div className="pl-10">
                  <VideoComponent
                    video={learnerTask?.video}
                    onError={() => {}}
                    parentClassName="rounded-md overflow-hidden"
                  />
                </div>
              </Grid>
              <Grid size={{ xs: 5 }}>
                {videoURL.includes(
                  'https://stafflinkcomau.sharepoint.com/:v:/r/sites/',
                ) ||
                (videoURL.includes('stafflinkcomau.sharepoint.com') &&
                  !videoURL.match(/src="([^"]+)"/)) ? (
                  <WarningLabel
                    title="Stream URL Issue"
                    description="If you are trying to link an MS Stream video, please make sure you are using the embed link. You can find this by clicking the Share button on the video and selecting Embed."
                  />
                ) : (
                  <WarningLabel
                    title="Please be mindful of recording sensitive information online."
                    description="The Hub allows you to restrict certain users and user
                groups from accessing these videos within The Hub platform. However,
                publicly hosted videos and videos accessible via a public URL may be
                accessed externally by unwanted parties."
                  />
                )}
              </Grid>
            </Grid>
          )}
          <Grid container size={{ xs: 2 }} direction="row" columnSpacing={1.5}>
            <Grid size={{ xs: 2 }}>
              {visibleText && (
                <CheckIcon className="mt-6 h-6 w-6 text-green-500" />
              )}
            </Grid>
            <Grid size={{ xs: 2 }}>
              <Label
                name="text"
                className="rw-label"
                style={{ marginLeft: 'calc(1rem - 2%)', paddingLeft: '20%' }}
                errorClassName="rw-label rw-label-error"
              >
                Text
              </Label>
              <Label
                name="text-optional"
                style={{ marginLeft: 'calc(1rem - 2%)', paddingLeft: '20%' }}
                className="rw-label mt-1 text-xs text-gray-400"
              >
                Optional
              </Label>
            </Grid>
          </Grid>
          <Grid size={{ xs: 10 }}>
            <div className="ml-10 mt-6">
              <div className="mb-10">
                {visibleText ? (
                  <RichTextEditor
                    defaultValue={learnerTask?.text}
                    onChange={handleDebounceRichTextEditorChange}
                    name="text"
                    id="tools-rte"
                    debounce={800}
                    className="max-h-[200px]"
                  />
                ) : (
                  <Button
                    onClick={() => setVisibleText(true)}
                    disableRipple
                    className="border-dashed border-gray-300 normal-case text-indigo-600"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    endIcon={<PlusCircleIcon className="h-4 w-4" />}
                  >
                    Add text
                  </Button>
                )}
              </div>

              {visibleText && showVideoTick && (
                <Grid size={{ xs: 12 }}>
                  <LessonTextLayoutOptions
                    textLayout={textLayout}
                    onChange={handleTextLayoutToggle}
                  />
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </Form>
    </div>
  )
}

export default VideoTaskForm
