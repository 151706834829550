import React, { useState, useEffect, useRef } from 'react'

import { TrashIcon } from '@heroicons/react/24/outline'
import { TextField } from '@mui/material'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'
import type {
  DeleteLearnerCourseMutation,
  DeleteLearnerCourseMutationVariables,
  RankLearnerItemsMutation,
  RankLearnerItemsMutationVariables,
} from 'types/graphql'

import { Form, Label } from '@redwoodjs/forms'
import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import DragHandle from 'src/components/DragHandle/DragHandle'
import GoalTargetIcon from 'src/components/Goals/GoalTargetIcon/GoalTargetIcon'
import HeroImageUploader from 'src/components/HeroImageUploader/HeroImageUploader'
import IconButton from 'src/components/IconButton/IconButton'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import InPlaceEditor from 'src/components/Library/InPlaceEditor/InPlaceEditor'
import {
  LinkedRowList,
  type LinkedResourceRow,
} from 'src/components/Library/LinkedContentBadge/LinkedContentBadge'
import Switch from 'src/components/Library/Switch'
import Modal from 'src/components/Modal/Modal'
import LearnerAssociatedMilestonesList from 'src/components/Settings/LearnerAssociatedMilestonesList/LearnerAssociatedMilestonesList'
import LearnerExistingMilestonesForm from 'src/components/Settings/LearnerExistingMilestonesForm/LearnerExistingMilestonesForm'
import Status from 'src/components/Status/Status'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import { DELETE_COURSE_MUTATION } from 'src/lib/queries/learner/learnerCourse'
import RANK_ITEM_MUTATION from 'src/lib/queries/learner/rank'
import { useAuth } from 'src/Providers'
import { published, getDraggableItemStyle, reorderItems } from 'src/Util'

import {
  LearnerCategories,
  LearnerCourseRefetch,
} from '../../EditLearnerCourseCell'

interface Props {
  activity: any
  course: any
  chapters: any
  setChapters: any
  setOrderedChapters: any
  togglePublished(learnerItem: any)
  onSave(id: number, data: any)
  onSelectedChapter(chapter: any)
  onDeleteActivity(id: number, name: string, mainView: boolean)
  onCategoryChange(id: number, categoryId: number)
  updateCourseName(id: number, data: any)
  refetch: LearnerCourseRefetch
  linkedWorkflowsRows: LinkedResourceRow[]
  categories: LearnerCategories
}

const CourseDetails: React.FC<Props> = ({
  course,
  chapters,
  setChapters,
  setOrderedChapters,
  onSave,
  onDeleteActivity,
  onSelectedChapter,
  togglePublished,
  onCategoryChange,
  updateCourseName,
  categories,
  linkedWorkflowsRows,
  refetch,
}) => {
  const { currentUser } = useAuth()
  const clientId = currentUser.parentData.id
  const index = categories?.findIndex((x) => x?.id === course.learnerCategoryId)
  const [orderedLearnerTasks, setOrderedLearnerTasks] = useState<
    any[] | undefined
  >()
  const [categoryValue, setCategoryValue] = useState(categories[index])
  const [categoryInputValue, setCategoryInputValue] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)
  const [delCourseModalOpen, setDelCourseModalOpen] = useState(false)
  const [milestonesModalContent, setMilestonesModalContent] = useState({
    id: null,
    milestones: [],
    perClientMilestones: [],
  })
  const [associatedMilestonesModalOpen, setAssociatedMilestonesModalOpen] =
    useState(false)
  const confirm = useConfirm()

  const onSubmit = (newValue) => {
    const id = course?.id
    onCategoryChange(id, newValue.id)
  }

  const [rankLearnerItems] = useMutation<
    RankLearnerItemsMutation,
    RankLearnerItemsMutationVariables
  >(RANK_ITEM_MUTATION, {
    onCompleted: async () => {
      await refetch()
      setIsUpdating(false)
      setOrderedLearnerTasks(null)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    if (orderedLearnerTasks) {
      // setIsUpdating to true as a stop to disallow further changes until after the mutation completes
      setIsUpdating(true)
      const reorderedItemsInput = orderedLearnerTasks.map((item, index) => ({
        id: item.id,
        rank: index + 1,
      }))
      rankLearnerItems({
        variables: {
          input: { itemType: 'Task', items: reorderedItemsInput },
        },
      })
    }
  }, [orderedLearnerTasks])

  const onChapterDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    if (isUpdating) {
      return
    }

    const reorderedItems = reorderItems(
      chapters,
      result.source.index,
      result.destination.index,
    )

    setChapters(reorderedItems)
    setOrderedChapters(reorderedItems)
  }

  const useDraggableInPortal = () => {
    const self: any = useRef({}).current

    useEffect(() => {
      const div = document.createElement('div')
      div.style.position = 'absolute'
      div.style.pointerEvents = 'none'
      div.style.top = '0'
      div.style.width = '100%'
      div.style.height = '100%'
      self.elt = div
      document.body.appendChild(div)
      return () => {
        document.body.removeChild(div)
      }
    }, [self])

    return (render) =>
      (provided, ...args) => {
        const element = render(provided, ...args)
        if (provided.draggableProps.style.position === 'fixed') {
          return createPortal(element, self.elt)
        }
        return element
      }
  }

  const renderDraggable = useDraggableInPortal()

  const [deleteLearnerCourse] = useMutation<
    DeleteLearnerCourseMutation,
    DeleteLearnerCourseMutationVariables
  >(DELETE_COURSE_MUTATION, {
    onCompleted: async () => {
      await refetch()
      toast.success('Course deleted')
      navigate(routes.settingsLearnerCategories())
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const onDeleteCourse = (id, name) => {
    confirm({
      title: `Delete ${name}?`,
      description: (
        <div>
          <p>Are you sure you want to delete this course?</p>
          <LearnerAssociatedMilestonesList
            learnerCourses={[course]}
            milestones={course.associatedMilestones}
            perClientMilestones={course.learnerMilestonesPerClient}
            type={'course'}
          />
          {linkedWorkflowsRows?.length > 0 && (
            <>
              <p className="my-3">
                This Course is linked to HubDash.
                <br />
                Archiving it will remove it from the following linked Cards:
              </p>
              <LinkedRowList linkedWorkflowsRows={linkedWorkflowsRows} />
            </>
          )}
        </div>
      ),
      confirmationText: 'Delete',
      confirmationButtonProps: { color: 'error', className: 'bg-red-500' },
    }).then((isConfirmed) => {
      if (!isConfirmed) return
      deleteLearnerCourse({ variables: { id } })
    })
  }

  const onViewAssociatedMilestones = () => {
    setMilestonesModalContent({
      id: course.id,
      milestones: course.associatedMilestones,
      perClientMilestones: course.learnerMilestonesPerClient,
    })
    setAssociatedMilestonesModalOpen(true)
  }

  //TODO - discuss setting the author field
  return (
    <>
      <div className="flex w-full flex-col items-center justify-center text-center">
        <HeroImageUploader course={course} onSave={onSave} />
        <div className="w-full border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between">
            <InPlaceEditor
              id={'courseName' + course?.id}
              value={course?.name}
              onChange={(newName) => updateCourseName(course?.id, newName)}
              title="Change Course Title"
            />

            <div className="flex flex-row items-center justify-end">
              {course?.associatedMilestones?.length > 0 && (
                <IconButton
                  className="h-5 w-5"
                  danger
                  onClick={onViewAssociatedMilestones}
                >
                  <GoalTargetIcon sizeInPixels={24} />
                </IconButton>
              )}
              <IconButton
                className="mr-4 h-8 w-8"
                danger
                onClick={() => onDeleteCourse(course?.id, course?.name)}
              >
                <TrashIcon className="h-5 w-5 text-red-500" />
              </IconButton>
              <Switch
                data-testid="course-details-publish"
                size="small"
                checked={published(course)}
                onChange={() => {
                  togglePublished(course)
                }}
                title={published(course) ? 'Published' : 'Unpublished'}
              />
              <Status published={published(course)} />
            </div>
          </div>
        </div>
        <div className="w-full max-w-3xl pb-4">
          <Form className="flex w-full flex-col text-left">
            <div className="mt-4">
              <Label
                name="learnerCategoryId"
                className="rw-label pb-3"
                errorClassName="rw-label rw-label-error"
              >
                Category
              </Label>
              <Autocomplete
                disableClearable
                fullWidth
                size="small"
                value={categoryValue}
                onChange={(_: any, newValue: any) => {
                  setCategoryValue(newValue)
                  onSubmit(newValue)
                }}
                inputValue={categoryInputValue}
                onInputChange={(_, newInputValue) => {
                  setCategoryInputValue(newInputValue)
                }}
                options={categories}
                getOptionLabel={(option: { id: number; name: string }) =>
                  option.name
                }
                // sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </Form>
        </div>

        <div className="mb-3 mt-5 w-full max-w-3xl">
          <h3
            data-testid="course-details-chapters-title"
            className="text-left text-lg font-medium leading-6 text-gray-600"
          >
            {course?.learnerActivities?.length !== 1 ? 'Chapters' : 'Chapter'}
          </h3>
        </div>
        <div className="w-full max-w-3xl">
          <DragDropContext onDragEnd={onChapterDragEnd}>
            <Droppable droppableId={(course?.id ?? '0') + ''}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {chapters?.map((lesson, lessonIndex) => (
                    <Draggable
                      key={lesson.id}
                      draggableId={lesson.id + ''}
                      index={lessonIndex}
                    >
                      {renderDraggable((provided, snapshot) => (
                        <div
                          className="divide-gray-20 divide-y px-0"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getDraggableItemStyle(
                            provided.draggableProps.style,
                          )}
                        >
                          <div className="py-1">
                            <div
                              tabIndex={0}
                              onKeyDown={() => {}}
                              onClick={() => {
                                onSelectedChapter(lesson)
                              }}
                              role="button"
                              className="h-min-2 group flex w-full items-center space-x-2 rounded-md py-1 hover:bg-gray-100"
                            >
                              <span className="wrap d-flex inline-flex w-full items-center justify-between">
                                <div
                                  {...provided.dragHandleProps}
                                  className="flex flex-row items-center"
                                >
                                  <DragHandle className="my-1" size={15} />
                                </div>

                                <div
                                  className={
                                    snapshot.isDragging &&
                                    !snapshot.draggingOver
                                      ? 'cursor-no-drop text-red-600 line-through'
                                      : 'ml-1 w-full text-sm font-medium text-gray-800'
                                  }
                                >
                                  <div className="flex items-center justify-between">
                                    <div className="">
                                      <h3 className="text-md font-small leading-6 text-gray-900">
                                        {lesson?.name}
                                      </h3>
                                    </div>
                                    <div className="flex items-center pr-2">
                                      <IconButton
                                        className="mr-4 h-8 w-8"
                                        danger
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          onDeleteActivity(
                                            lesson.id,
                                            lesson.name,
                                            false,
                                          )
                                        }}
                                      >
                                        <TrashIcon className="h-5 w-5 text-red-500" />
                                      </IconButton>

                                      <Switch
                                        size="small"
                                        checked={published(lesson)}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          togglePublished(lesson)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <Modal
        open={delCourseModalOpen}
        onClose={() => setDelCourseModalOpen(false)}
        dialogClassName={'!p-0 max-w-[500px]'}
        title={'Delete Course'}
        disablePortal={false}
      >
        <LearnerExistingMilestonesForm
          milestones={milestonesModalContent.milestones}
          perClientMilestones={milestonesModalContent.perClientMilestones}
          learnerCourses={[course]}
          type="course"
          onDelete={() => {
            milestonesModalContent.id &&
              deleteLearnerCourse({
                variables: { id: milestonesModalContent.id },
              })
          }}
          onClose={() => setDelCourseModalOpen(false)}
        />
      </Modal>
      <Modal
        open={associatedMilestonesModalOpen}
        onClose={() => setAssociatedMilestonesModalOpen(false)}
        dialogClassName={'!p-0 max-w-[500px]'}
        title={'Associated Milestones'}
        disablePortal={false}
      >
        <LearnerAssociatedMilestonesList
          learnerCourses={[course]}
          milestones={milestonesModalContent.milestones}
          perClientMilestones={milestonesModalContent.perClientMilestones}
          type={'course'}
        />
      </Modal>
    </>
  )
}

export default CourseDetails
